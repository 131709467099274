import { Modal, Button, Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import { COLORS } from 'src/utils/styles';

interface FeedbackMessage {
    title: string
    feedback: string
    handleClose: () => void
}

export function FeedbackMessage(props: FeedbackMessage) {
  const {feedback, handleClose, title} = props  
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    if(feedback){
        setOpened(true)
    }else{
        setOpened(false)
    }
  }, [feedback])

  const onClose = () => {
    handleClose()
  }

  return (
    <>
      <Modal
        opened={opened}
        onClose={onClose}
        transitionProps={{transition: "slide-down", timingFunction: "ease", duration: 500}}
        title={title}
        withOverlay ={false}
        closeOnClickOutside
        styles= {(theme) => ({body: {marginTop: 10, fontSize: 11,}, header: { height: 20}, title: {color: 'green'}, close: {}})}
      >
        <Text>{feedback}</Text>
      </Modal>
    </>
  );
}