import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ChatMessage, FlaggedData } from 'src/interfaces/Chat';
import useResponsive from 'src/hooks/useResponsive';
import useViewportHeight from 'src/hooks/useViewPortHeight';
import ReadOnlyBotMessage from './BotMessage/ReadOnlyBotMessage';
import UserMessage from './UserMessage';
import ScrollToBottomButton from './Layout/ScrollToBottom';
import FeedbackModal from './Modals/FeedbackModal';

interface ReadOnlyChatProps {
  messages: ChatMessage[];
  childUsername: string;
  onFlagMessage: (flaggedData: FlaggedData) => void;
}

const ReadOnlyChat: React.FC<ReadOnlyChatProps> = ({ messages, childUsername, onFlagMessage }) => {
  const viewport = useRef<HTMLDivElement>(null);
  const [isAtBottomButtonTrigger, setAtBottomButtonTrigger] = useState(true);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const { isMobile } = useResponsive();
  const [feedbackType, setFeedbackType] = useState<string | null>(null);
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const [flaggedMessageIndex, setFlaggedMessageIndex] = useState<number | null>(null);

  useViewportHeight();

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const handleScrollButtonTrigger = () => {
      if (!viewport.current) return;
      const bottomThreshold = isMobile ? 120 : 100;
      const isBottom = viewport.current.scrollHeight - viewport.current.scrollTop <= viewport.current.clientHeight + bottomThreshold;
      setAtBottomButtonTrigger(isBottom);
    };

    const handleScroll = () => {
      if (!viewport.current) return;
      const bottomThreshold = isMobile ? 30 : 50;
      const isBottom = viewport.current.scrollHeight - viewport.current.scrollTop <= viewport.current.clientHeight + bottomThreshold;
      setIsAtBottom(isBottom);
    };

    viewport.current?.addEventListener('scroll', handleScrollButtonTrigger);
    viewport.current?.addEventListener('scroll', handleScroll);

    return () => {
      viewport.current?.removeEventListener('scroll', handleScrollButtonTrigger);
      viewport.current?.removeEventListener('scroll', handleScroll);
    };
  }, [isMobile]);

  const scrollToBottom = () => {
    viewport.current?.scrollTo({
      top: viewport.current.scrollHeight,
      behavior: 'smooth',
    });
  };

  const handleFlagMessage = useCallback((index: number) => {
    setFlaggedMessageIndex(index);
    setShowFeedbackForm(true);
  }, []);

  const handleSubmitFeedback = useCallback((feedback: string) => {
    if (flaggedMessageIndex === null) return;

    const startIndex = Math.max(1, flaggedMessageIndex - 10);
    const msgsToFlag = messages.slice(startIndex, flaggedMessageIndex + 1).map((msg, i) => ({
      role: msg.role,
      content: msg.content,
      created_at: String(msg.created_at),
      is_flagged: i === flaggedMessageIndex - startIndex
    }));

    const flaggedData: FlaggedData = {
      child_username: childUsername,
      msgs_to_flag: msgsToFlag,
      feedback: feedback
    };
    onFlagMessage(flaggedData);
    handleCancelFeedback();
  }, [flaggedMessageIndex, messages, childUsername, onFlagMessage, feedbackType]);

  const handleCancelFeedback = () => {
    setShowFeedbackForm(false);
    setFeedbackType(null);
    setFlaggedMessageIndex(null);
  };

    const handleCloseFeedbackModal = () => {
    setShowFeedbackForm(false);
    setFeedbackType(null);
    setFlaggedMessageIndex(null);
  };

  return (
    <>
      <div
        ref={viewport}
        className={`flex-1 overflow-y-auto no-scrollbar py-8 px-2 space-y-8`}
      >
        {messages.map((message, index) => (
          message.role === 'ai' ? (
            <ReadOnlyBotMessage
              key={index}
              message={message.content}
              index={index}
              onFlagMessage={handleFlagMessage}
            />
          ) : (
            <UserMessage key={index} message={message.content} />
          )
        ))}
      </div>
      {!isAtBottomButtonTrigger && (
        <ScrollToBottomButton onClick={scrollToBottom} />
      )}
        <FeedbackModal
        isOpen={showFeedbackForm}
        onClose={handleCloseFeedbackModal}
        feedbackType={feedbackType}
        setFeedbackType={setFeedbackType}
        onSubmit={handleSubmitFeedback}
      />
    </>
  );
};

export default ReadOnlyChat;