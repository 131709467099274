import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { EyeIcon, EyeSlashIcon, CheckIcon } from "@heroicons/react/24/solid";
import { GoogleIcon } from "src/components/common/Icons/GoogleIcon";
import { Apple, EmailOutlined } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Center, Grid, Image, Loader, Stack, Text } from "@mantine/core";
import { authService } from "src/services/services/auth/auth.api";
import { ResponseKind } from "src/services/services/MainService";
import useResponsive from "src/hooks/useResponsive";
import { authRoutes } from "src/navigators/routes/auth";

const VerifyEmail: FunctionComponent = () => {
  const [verificationMessage, setVerificationMessage] = useState('');
  const url = window?.location?.href  
  const navigation = useNavigate();
  const {isMobile} = useResponsive()

  useEffect(() => {

    if(url.includes("token")){
        const urls = url.split("?");
        const breakTwo = urls[1].split('&')
        let token = breakTwo[0]
        let email = breakTwo[1]
        token = token.substring(token.indexOf('=') + 1);
        email = email.substring(email.indexOf('=') + 1)

        verifyEmail(token, email)
    }
  }, [url])

  const verifyEmail = async (token: string, email: string) => {
    const response = await authService.verifyEmail(token, email)
    
    if (response.kind == ResponseKind.OK) {
      setVerificationMessage("Email Verified. You can now proceed to login.");
      setTimeout(() => navigation(authRoutes.LOGIN), 5000); // Redirect after 5 seconds
    } else {
      setVerificationMessage("Invalid Token or Link Expired.");
    }
  };

  return (
    <Center maw={"75vh"} h={"100vh"} mx="auto">
            <Stack spacing={"lg"} align="center" w={"100%"} mx={"md"}>
             <Image src={'/logo.png'} alt="logo" width={200} />
            <Text align="center" size={"lg"}>
            {verificationMessage || "Please wait while we are verifying your account."}
            {!verificationMessage && <div className="w-full mt-4 flex items-center justify-center "><Loader color="#29748F" variant="bars" /></div>}
            </Text>
              {verificationMessage && verificationMessage.startsWith("Email Verified") && (
                <Button
                variant="outline"
                onClick={() => navigation("/auth/login")}>
                  Proceed to Login
                </Button>
              )}
            </Stack>

        </Center>
  );
};

export default VerifyEmail;
