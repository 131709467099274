import React, { useState, useEffect } from 'react';
import { Text, Select } from "@mantine/core";
import { GroupedPrices, PriceItem } from "src/interfaces/Payments";
import { ProPlanCard } from '../Cards/ProPlanCard';

const productDisplayNames: { [key: string]: string } = {
  "Basic": "1 child",
  "Standard": "2 children",
  "Premium": "3 children"
};

interface DetailedProOptionsProps {
  prices: GroupedPrices | null;
  onSubscribe: (priceId: string) => void;
  onCancel: () => void;
  currentPlan: PriceItem | null;
  preference: "Basic" | "Standard" | "Premium";
}

export const DetailedProOptions: React.FC<DetailedProOptionsProps> = (props) => {
  const { prices, onSubscribe, onCancel, currentPlan, preference } = props;
  const [selectedPackage, setSelectedPackage] = useState<string | null>(preference);

  useEffect(() => {
    if (currentPlan) {
      setSelectedPackage(currentPlan.product.name);
    }
  }, [currentPlan]);

  const getIntervalLabel = (interval: string) => {
    switch (interval) {
      case 'year': return 'Yearly';
      case 'month': return 'Monthly';
      default: return `${interval}`;
    }
  };

  const packageOptions = prices
    ? Object.keys(prices).map(productName => ({
      value: productName,
      label: productDisplayNames[productName] || productName
    }))
    : [];

  return (
    <div className="mb-4 w-full lg:w-3/4 xl:w-1/2 ">
      <Text size="xl" weight={500} className="mb-4">Choose Your Pro Plan</Text>
      {prices && (
        <>
          <div className='flex flex-col gap-2'>
            <Text>How many learners do you have?</Text>
            <Select
              classNames={{

                input: 'border-primary focus:border-primary',

              }}
              styles={(theme) => ({
                item: {
                  // applies styles to selected item
                  '&[data-selected]': {
                    '&, &:hover': {
                      backgroundColor: '#29748F',
                      color: 'white',
                    },
                  },

                  // applies styles to hovered item (with mouse or keyboard)
                  '&[data-hovered]': {},
                },
              })}
              placeholder="Choose a package"
              data={packageOptions}
              value={selectedPackage}
              onChange={setSelectedPackage}
              className="mb-4"
            />
          </div>


          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
            {selectedPackage && prices[selectedPackage].map((price) => (
              <ProPlanCard
                interval={price.recurring.interval}
                key={price.id}
                title={getIntervalLabel(price.recurring.interval)}
                price={price.original_price}
                discounted_price={price.promo_price}
                description={price.product.description}
                onSubscribe={() => onSubscribe(price.id)}
                onCancel={onCancel}
                isCurrentPlan={currentPlan?.lookup_key === price.lookup_key}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};