import React, { ReactNode } from 'react';
import ParentHeader from '../Navbar/ParentHeader';
import SimpleHeader from '../Navbar/SimpleHeader';
import useViewportHeight from 'src/hooks/useViewPortHeight';
import useResponsive from 'src/hooks/useResponsive';

interface LayoutProps {
  children: ReactNode;
  heading: ReactNode;
}

const SimpleLayout: React.FC<LayoutProps> = ({ children, heading }) => {

  return (
    <div className="flex flex-col h-screen">
      {/* Main Content Area */}
      <div className="flex flex-col flex-grow">
        {/* Header */}
        <SimpleHeader renderHeading={heading} />

        {/* Main Content */}
        <main className="flex-grow bg-gray-100">
          {children}
        </main>
      </div>
    </div>
  );
};

export default SimpleLayout;