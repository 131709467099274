import { Badge, Button, Card, List, Text, ThemeIcon } from "@mantine/core";
import Icon from "../common/Icons/Icons/Icon";
import { CustomButton } from "../common/Buttons/CustomButton";

interface PlanCardProps {
    title: string;
    price: number;
    description: string;
    billingPeriod: string;
    features: string[];
    buttonText: string;
    buttonVariant: 'outline' | 'default';
    buttonColor?: string;
    onButtonClick?: () => void;
    additionalButtons?: React.ReactNode;
    subscriptionDetails?: React.ReactNode;
    isCurrentPlan: boolean;
  }
  
export const PlanCard: React.FC<PlanCardProps> = ({
    title,
    price,
    description,
    features,
    buttonText,
    billingPeriod,
    buttonVariant,
    buttonColor,
    onButtonClick,
    additionalButtons,
    subscriptionDetails,
    isCurrentPlan
  }) => (
    <Card shadow="sm" p="lg" radius="md" withBorder className="w-full mx-auto">
      <div className="flex flex-col h-full justify-between">
        <div>
          <div className="flex justify-between items-start mb-4">
            <Text size="xl" weight={700}>{title}</Text>
            {isCurrentPlan && <Badge color="green">Current Plan</Badge>}
          </div>
          <Text size="sm" color="dimmed" className="mb-4">{description}</Text>
          <Text size="xl" weight={700} className="mb-4">
            SG ${price.toFixed(2)} <span className="text-sm font-normal">{billingPeriod === 'year' ? '/year' : '/month'}</span>
          </Text>
          {subscriptionDetails}
          <List
            spacing="sm"
            size="sm"
            icon={
              <ThemeIcon className="bg-primary" size={20} radius="xl">
                <Icon name='check' size="12" />
              </ThemeIcon>
            }
            className="mb-4"
          >
            {features.map((feature, index) => (
              <List.Item key={index}>{feature}</List.Item>
            ))}
          </List>
        </div>
        <div>
          <CustomButton
            variant={buttonVariant}
            color={buttonColor}
            onClick={onButtonClick}
            className="mb-2"
          >
            {buttonText}
          </CustomButton>
          {additionalButtons}
        </div>
      </div>
    </Card>
  );