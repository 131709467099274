import React from 'react';
import { useForm, yupResolver } from "@mantine/form";
import * as Yup from "yup";
import { Text } from "@mantine/core";
import InputField from "src/components/common/Inputs/InputField";
import { CustomButton } from '../../common/Buttons/CustomButton';
import SelectField from '../../common/Inputs/SelectField';
import { ChildLevel } from 'src/enums/Child';
import { LEVELS, SCHOOLS, childUpdateSchema } from './metadata';
import { ChildUpdationData, ChildrenData } from 'src/interfaces/Children';
import { ProfileCard } from 'src/components/ProfileCard';

interface EditChildProps {
  user: ChildrenData
  onUpdate: (updatedData: Partial<ChildUpdationData>) => void;
}

export const EditChildForm: React.FC<EditChildProps> = ({ user, onUpdate }) => {
  const form = useForm<ChildUpdationData>({
    initialValues: {
      name: user.name,
      level: user.level as ChildLevel,
      school: user.child_school,
      password: '',
    },
    validate: yupResolver(childUpdateSchema),
    validateInputOnBlur: true,
    validateInputOnChange: true,
  });

  const handleSubmit = (values: ChildUpdationData) => {
    const updatedData: Partial<ChildUpdationData> = {};
    if (values.name !== user.name) updatedData.name = values.name;
    if (values.level !== user.level) updatedData.level = values.level;
    if (values.school !== user.child_school) updatedData.school = values.school;
    if (values.password !== '') updatedData.password = values.password;

    onUpdate(updatedData);
  };

  return (
    <div className='w-full p-4'>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <div className='space-y-6'>
        <div className="flex items-center mb-6">
        <ProfileCard 
        avatar={user.profile_picture_url}
        name={user.name}
        username={user.username}
        />

      </div>
          <InputField
            label="Name"
            placeholder="Enter full name"
            {...form.getInputProps('name')}
          />
            <SelectField
              label="Level"
              placeholder="Select level"
              data={LEVELS}
              {...form.getInputProps('level')}
            />
          <SelectField
            label="School"
            placeholder="Select school"
            data={SCHOOLS}
            {...form.getInputProps('school')}
          />
          <InputField
            label="Username"
            value={user.username}
            disabled
          />
          <InputField
            label="New Password"
            type="password"
            placeholder="Enter new password (leave blank to keep current)"
            {...form.getInputProps('password')}
            showPasswordToggle
          />
          <Text size="xs" color="gray" mt={-8} mb={16}>Use at least one capital letter and a number in your password.</Text>
          <CustomButton type="submit" disabled={!form.isValid()}>
            Update Information
          </CustomButton>
        </div>
      </form>
    </div>
  );
};