import Config from "src/config";
import { api, Api } from "../MainService/api";
import { ErrorKind, GeneralApiProblem } from "../MainService/apiProblem";
import { UserBase, UserToken } from "src/interfaces";
import { DocumentContainerType } from "src/enums/Documents";
import { BlockBlobClient } from "@azure/storage-blob";
import { ResponseKind } from "../MainService";
import { ChildCreationData, ChildrenData, ChildUpdationData } from "src/interfaces/Children";
import { MessageList } from "../chat/chat.api.types";
import { ChatMessage, ChatSession, FlaggedData } from "src/interfaces/Chat";

const PARENT_URL = Config.PARENT_URL;
const ADMIN_URL = Config.ADMIN_URL;
const AZURE_URL = "https://tutorbotdevstorage.blob.core.windows.net";

export class ParentApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getChildren() {
    try {
      const response = await this.api.apisauce.get<{children: ChildrenData[]}>(`${PARENT_URL}/list-children`);
      let data = await this.api.getResponse(response, true);
      return data;
    } catch (e) {
      console.log(e);
      return { kind: ErrorKind.BAD_DATA_ERROR };
    }
  }

  async getChildChat(username: string, sessionId: string) {
    try {
      const response = await this.api.apisauce.get<{title: string, messages: ChatMessage[]}>(`${PARENT_URL}/view-child-chat-session?session_id=${sessionId}&child_username=${username}`);
      let data = await this.api.getResponse(response, true);
      return data;
    } catch (e) {
      console.log(e);
      return { kind: ErrorKind.BAD_DATA_ERROR };
    }
  }

  async getChildSessions(username: string) {
    try {
      const response = await this.api.apisauce.get<{chat_sessions: ChatSession[]}>(`${PARENT_URL}/list-child-chat-sessions?child_username=${username}`);
      let data = await this.api.getResponse(response, true);
      return data;
    } catch (e) {
      console.log(e);
      return { kind: ErrorKind.BAD_DATA_ERROR };
    }
  }

  async setProfilePic(profilePic: File) {
    try {
      const formData = new FormData();
      formData.append('profile_picture', profilePic);
  
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
  
      const response = await this.api.apisauce.post(`${PARENT_URL}/register-child`, formData, config);
      let data = await this.api.getResponse(response, true);
      return data;
    } catch (e) {
      console.log(e);
      return { kind: ErrorKind.BAD_DATA_ERROR, errors: "Bad data error" };
    }
  }

  async createChild(child: ChildCreationData, profilePic: File | null) {
    try {
      const formData = new FormData();
      
      // Append child data as JSON string
      formData.append('child_data', JSON.stringify(child));
      
      // Append profile picture if provided
      if (profilePic != null) {
        formData.append('profile_picture', profilePic);
      }
  
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
  
      const response = await this.api.apisauce.post<ChildrenData>(`${PARENT_URL}/register-child`, formData, config);
      let data = await this.api.getResponse(response, true);
      return data;
    } catch (e) {
      console.log(e);
      return { kind: ErrorKind.BAD_DATA_ERROR, errors: "Bad data error" };
    }
  }

  async flagMessage(flaggedData: FlaggedData) {
    try {
      const response = await this.api.apisauce.post(`${PARENT_URL}/flag-child-chat`, flaggedData)

      let data = await this.api.getResponse(response, true);
      return data;
    } catch (e) {
      console.log(e);
      return { kind: ErrorKind.BAD_DATA_ERROR };
    }
  }

  async updateChild(childUsername: string, childUpdateData: Partial<ChildUpdationData>) {
    try {
      const response = await this.api.apisauce.put(
        `${PARENT_URL}/update-child/${childUsername}`,
        childUpdateData
      );
      let data = await this.api.getResponse(response, true);
      return data;
    } catch (e) {
      console.log(e);
      return { kind: ErrorKind.BAD_DATA_ERROR, errors: "Bad data error" };
    }
  }

  async setChildPreference(preference: number) {
    try {
      const response = await this.api.apisauce.post(
        `${PARENT_URL}/set-child-preference?child_preference=${preference}`,
      );
      let data = await this.api.getResponse(response, true);
      return data;
    } catch (e) {
      console.log(e);
      return { kind: ErrorKind.BAD_DATA_ERROR, errors: "Bad data error" };
    }
  }

  async deleteChild(username: string) {
    try {
      const response = await this.api.apisauce.get(`${PARENT_URL}/remove-child?child_username=${username}`);
      let data = await this.api.getResponse(response);
      
      return data;
    } catch (e) {
      // alert("You entered some bad data")
      return { kind: ErrorKind.BAD_DATA_ERROR, errors: "Bad Data" };
    }
  }
}

export const parentService = new ParentApi(api);
