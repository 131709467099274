import React from 'react';

import { StyledContainer } from "src/components/common/StyledComponents/Container";
import { StyledContainerInset } from "src/components/common/StyledComponents/BackgroundContainer";
import useResponsive from "src/hooks/useResponsive";
import RegisterChildForm, { RegisterFormData } from "src/components/Forms/Child/RegisterChild";
import useChildrenData from "src/hooks/useChildrenData";
import { useNavigate } from "react-router-dom";
import SimpleLayout from 'src/components/Layout/SimpleLayout';
import { Button, Image, Text } from '@mantine/core';
import { parentRoutes } from 'src/navigators/routes/ParentRoutes';
import { sendFailureNotification } from 'src/utils/notifications';
import { NotificationTypes } from 'src/enums/Notifications';
import Icon from 'src/components/common/Icons/Icons/Icon';
import useViewportHeight from 'src/hooks/useViewPortHeight';
import { CustomButton } from 'src/components/common/Buttons/CustomButton';

const RegisterChildOnboardingScreen = () => {
  const navigate = useNavigate();
  useViewportHeight()
  const {isMobile} = useResponsive()
  const { backgroundColor, backgroundColorExterior } = useResponsive();
  const { createChild } = useChildrenData();

  const header = (
    <div className="flex flex-row justify-between">
      <Image src={'/landing/logo.png'} className='max-w-[100px] max-h-[100px]' />
      <></>
    </div>
  );


  let heightStyle;
  if (isMobile) {
    // Is tablet or mobile (Max width 1024px)
    heightStyle = 'calc((var(--vh, 1vh) * 100) - 40px)';
  } else {
    // Desktop (Min width 1024px)
    heightStyle = 'calc((var(--vh, 1vh) * 100) - 70px)';
  }

  return (
    <SimpleLayout heading={header}>
      <StyledContainer theme={{ backgroundColor: backgroundColorExterior }}>
        <StyledContainerInset theme={{ backgroundColor: backgroundColor  }}>
          <div style={{height: heightStyle}} className="flex overflow-hidden w-full flex-col items-center justify-start sm:justify-center py-4 ">
            <div className='flex flex-col items-center justify-start sm:justify-center mb-4'>
              <Text size="xl" fw="bold">Great!</Text>
              <Text size="md">Let's register your first learner.</Text>
            </div>
            <RegisterChildForm
              onSubmit={async (values: RegisterFormData, cb = () => { 
                navigate(parentRoutes.DASHBOARD, { replace: true })
              }) => {
                const temp = {
                  name: `${values.firstName} ${values.lastName}`,
                  level: values.grade,
                  username: values.username,
                  password: values.password,
                  school: values.school,
                  date_of_birth: `${values.birthYear}-01-01`,
                };

                const res = await createChild(temp, values.image);
                if (res === true) {
                  navigate(parentRoutes.DASHBOARD, { replace: true })
                }
              }}
            />

            <div className='sticky bottom-0 w-full flex justify-end px-10'>
            <Button
              variant="subtle"
              onClick={() => navigate(parentRoutes.DASHBOARD, { replace: true })}
              rightIcon={<Icon name='next' color='#29748F' className='w-4 h-4' />}
            >
              <Text className='text-base font-bold text-primary'>Skip</Text>
            </Button>
            </div>
        
          </div>
        </StyledContainerInset>
      </StyledContainer>
    </SimpleLayout>
  );
};

export default RegisterChildOnboardingScreen;