import React from 'react';
import { Badge, Button, Card, Text } from "@mantine/core";

interface ProPlanCardProps {
  title: string;
  price: number;
  interval: string;
  description: string;
  discounted_price: number;
  onSubscribe: () => void;
  onCancel: () => void;
  isCurrentPlan: boolean;
}

export const ProPlanCard: React.FC<ProPlanCardProps> = ({ 
  title, 
  price, 
  description, 
  interval,
  onSubscribe, 
  discounted_price,
  onCancel,
  isCurrentPlan 
}) => (
  <Card shadow="sm" p="lg" radius="md" withBorder>
    <div className="flex justify-between items-start mb-2">
      <Text size="lg" weight={700}>{title}</Text>
      {isCurrentPlan && <Badge color="green">Current Plan</Badge>}
    </div>
    <Text size="sm" color="dimmed" className="mb-4">{description}</Text>
    <div className="mb-4">
      <Text size="xl" weight={700} className="inline-block mr-2">
        <span className="text-primary font-bold">${interval == 'year' ? (discounted_price / 12).toFixed(2) : discounted_price}</span>{' '}
        <span className="text-sm font-normal text-gray-500">/month</span>
      </Text>
      <Text size="md" className="inline-block line-through text-gray-400">
        ${interval == "year" ? (price / 12).toFixed(2) : price}
      </Text>
    </div>
    <Button
      variant={isCurrentPlan ? 'outline' : "filled"} 
      className={`${isCurrentPlan ? 'text-red-500 border-red-500' : 'bg-primary'}`}
      fullWidth 
      size='sm'
      radius='md'
      onClick={isCurrentPlan ? onCancel : onSubscribe}
    >
      {isCurrentPlan ? "Cancel Subscription" : "Subscribe"}
    </Button>
  </Card>
);