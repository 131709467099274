import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Image, Text } from "@mantine/core";
import { AuthLayout } from 'src/components/Layout/AuthLayout';
import { VisualSideLayout } from 'src/components/Layout/VisualsLayout';
import { UserTypeToggle } from 'src/components/Toggles/UserTypeToggle';
import { LearnerSignInForm } from 'src/components/Forms/LearnerSignin';
import { ParentSignInForm } from 'src/components/Forms/ParentSignin';
import { authService } from "src/services/services/auth/auth.api";
import { ResponseKind } from "src/services/services/MainService";
import { useAppDispatch } from 'src/redux/redux-store/hooks';
import { handleUserUpdate, updateAdminSettings, updateAppSettings, updateUser } from 'src/redux/redux-store';
import { UserRole } from 'src/enums/User';
import { userRoutes } from 'src/navigators/routes/user';
import { useAuth } from 'src/hooks/useAuth';
import { authRoutes } from 'src/navigators/routes/auth';
import { AuthHeader } from 'src/components/Cards/AuthHeaderCard';
import { clearStorageToken } from 'src/utils/auth.storage';
import { parentRoutes } from 'src/navigators/routes/ParentRoutes';
import useResponsive from 'src/hooks/useResponsive';
import { Notifications } from '@mantine/notifications';

const SignIn: React.FC = () => {
  const [userType, setUserType] = useState<'Learner' | 'Parent'>('Learner');
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const {login} = useAuth()
  const {isMobile} = useResponsive()

  const navigateToSignUp = useCallback(() => {
    navigate(authRoutes.SIGNUP);
  }, []);

  const navigateToForgotPassword = useCallback(() => {
    navigate(authRoutes.FORGOT_PASSWORD)
  }, [])

  const loginAsChild = useCallback(async (values: any) => {
    setLoading(true);
    try {
      const response = await authService.logIn(
        values.username,
        values.password
      );
      if (response.kind === ResponseKind.OK) {
        const tokenData = response.data;
  
        if (tokenData) {
          if (tokenData.role === UserRole.user) {
            login(tokenData)
            navigate(userRoutes.DASHBOARD, {replace: true});
          } else {
            clearStorageToken()
            alert("Invalid credentials");
            return;
          }
        } else {
          alert("Invalid credentials");
        }
      } else {
        throw new Error("Login failed");
      }
    } catch (e) {
      alert("Invalid credentials");
    } finally {
      setLoading(false);
    }
  }, []);


  const loginAsParent = useCallback(async (values: any) => {
    setLoading(true);
    try {
      const response = await authService.logIn(
        values.email,
        values.password
      );
      if (response.kind === ResponseKind.OK) {
        const tokenData = response.data;
        if (tokenData) {
          if(tokenData.role == UserRole.parent) {
              login(tokenData);
              const user = await dispatch(handleUserUpdate())

              if(user){
                if(user.walkthrough_completed){
                  navigate('/parent', {replace: true})
                }else{
                  navigate(parentRoutes.PREPARE_LEARNERS, {replace: true})
                }
              }else{
                throw new Error("Login failed: User not found");
              }
            } else {
              throw new Error("Login failed: Incorrect user type");
          }
        } else {
          alert("Invalid credentials");
        }
      } else {
        throw new Error("Login faile: Invalid credentials");
        
      }
    } catch (e) {
      alert("Invalid credentials");
      clearStorageToken();
    } finally {
      setLoading(false);
    }
  }, []);

  const formComponent = (
    <div className="w-full max-w-md space-y-6">
      <AuthHeader title='Sign In' subtitle='Enter your details to sign in' />
      <UserTypeToggle userType={userType} onToggle={setUserType} />
      {userType === 'Learner' ? (
        <LearnerSignInForm handleForgotPassword={navigateToForgotPassword} onSubmit={loginAsChild} loading={loading} error={error} />
      ) : (
        <ParentSignInForm handleForgotPassword={navigateToForgotPassword} onSubmit={loginAsParent} loading={loading} error={error} />
      )}
      <Text className="text-center text-sm">
        Don't have an account? <a onClick={navigateToSignUp} className="text-blue-600 cursor-pointer">{userType == "Parent" ? 'Sign Up' : 'Get a parent to Sign up'}</a>
      </Text>
    </div>
  );

  return (
    <>
      <Notifications
              position={isMobile ? "top-center" : "bottom-right"}
              limit={isMobile ? 1 : 5}
            />
          <AuthLayout
        visualComponent={<VisualSideLayout/>}
      formComponent={formComponent}
    />
    </>

  );
};

export default SignIn;


