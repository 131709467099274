import React, { useCallback, useEffect, useState } from 'react';
import { Card, Text, Button } from '@mantine/core';
import { TabSelector } from 'src/components/Toggles/TabSelectorToggle';
import DynamicLayout from 'src/components/Layout/DynamicLayout';
import { StyledContainer } from 'src/components/common/StyledComponents/Container';
import { StyledContainerInset } from 'src/components/common/StyledComponents/BackgroundContainer';
import { Notifications } from '@mantine/notifications';
import useResponsive from 'src/hooks/useResponsive';
import ParentChatSidebar from 'src/components/Sidebars/Parent/ParentSIdebarHome';
import ParentProfileSidebar from 'src/components/Sidebars/Parent/ParentSIdebarProfile';
import { DetailedProOptions } from 'src/components/Layout/ProPlansLayout';
import { useAppSelector } from 'src/redux/redux-store/hooks';
import { useNavigate } from 'react-router-dom';
import { usePayments } from 'src/hooks/usePayment';

const PricingScreen = () => {
  const user = useAppSelector((state) => state.user);
  const navigate = useNavigate()
  const { isMobile, backgroundColorExterior, backgroundColor } = useResponsive();
  const {createSession, userPlan, groupedPrices, navigateToPortal, fetchPrices} = usePayments()

  const handleCancellation = async () => {
    const url = await navigateToPortal();
    if (url) {
      window.location.href = url;
    }
  }

  const handleSubscribe = async (priceId: string) => {
    const url = await createSession(priceId)

    if(url){
      window.open(url, '_blank');
    }
  }

  const header = (
    <div className="flex flex-col">
      <Text size={"lg"} fw={"bold"}>Profile </Text>
    </div>
  );

  useEffect(() => {
    if(groupedPrices){
      return
    }
    fetchPrices()
  }, [groupedPrices])

  return (
    <DynamicLayout
      heading={header}
      SidebarComponent={(props) => (
        <ParentProfileSidebar
          {...props}
        />
      )}
    >
      <StyledContainer theme={{ backgroundColor: backgroundColorExterior }}>
        <StyledContainerInset theme={{ backgroundColor: backgroundColor }}>
          <Notifications
            position={isMobile ? "top-center" : "bottom-right"}
            limit={isMobile ? 1 : 5}
          />
          <div className='h-full w-full p-8'>
            <DetailedProOptions 
            preference={user.child_preference === 1 ? "Basic" : user.child_preference === 2 ? "Standard" : "Premium"}
            onCancel={handleCancellation} prices={groupedPrices} onSubscribe={handleSubscribe} currentPlan={userPlan ? userPlan.plan_detail : null} />
          </div>
        </StyledContainerInset>
      </StyledContainer>
    </DynamicLayout>
  );
};

export default PricingScreen;