import React, { useMemo, useState } from 'react';
import {
  Navbar,
  Text,
  createStyles,
  useMantineTheme,
  ActionIcon,
  Skeleton,
} from "@mantine/core";
import { useAppDispatch, useAppSelector } from "src/redux/redux-store/hooks";
import { useNavigate } from "react-router-dom";
import { clearChatState, clearMessages, resetUser, setSelectedSession,} from "src/redux/redux-store";
import { chatService } from "src/services/services/chat/chat.api";
import { ResponseKind } from "src/services/services/MainService";
import { confirmDeletionModal, ConfirmFeedbackModal } from "./Modals/ConfirmationModal";
import useViewportHeight from "src/hooks/useViewPortHeight";
import { useChat } from "src/hooks/useChat";
import { ChatSession } from "src/interfaces/Chat";
import Icon from "./common/Icons/Icons/Icon";
import { CustomButton } from "./common/Buttons/CustomButton";
import { ChatBubbleOvalLeftIcon, TrashIcon } from '@heroicons/react/24/outline';
import ChatHistoryTile from './Chat/HistoryTile';
import { authService } from 'src/services/services/auth/auth.api';
import { authRoutes } from 'src/navigators/routes/auth';
import { clearToken } from 'src/utils/storage';
import { useAuth } from 'src/hooks/useAuth';
import { ChatSessionsList } from './Chat/ChatSessionsList';
import useResponsive from 'src/hooks/useResponsive';

interface SideBarProps {
  opened: boolean;
}

const useStyles = createStyles((theme) => ({
  parentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "white",

  },
}));

const Sidebar: React.FC<SideBarProps> = ({ opened }) => {
  const chatSessions = useAppSelector((state) => state.chat.sessions);
  const selectedSession = useAppSelector((state) => state.chat.selectedSession);
  const sessionsLoading = useAppSelector((state) => state.chat.sessionsLoading);
  const dispatch = useAppDispatch();
  const [isSignOutModalOpen, setIsSignOutModalOpen] = useState(false);
  const { classes } = useStyles();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user);
  const { newSession, deleteSession } = useChat();
  const theme = useMantineTheme();
  const { logout } = useAuth()
  const { isMobile } = useResponsive()

  useViewportHeight();

  const groupedChats = useMemo(() => {
    const now = new Date();
    const today = now.toDateString();
    const yesterday = new Date(now.setDate(now.getDate() - 1)).toDateString();

    return chatSessions.reduce<Record<string, ChatSession[]>>((acc, chat) => {
      const chatDate = new Date(chat.created_at);
      let group: string;

      if (chatDate.toDateString() === today) {
        group = 'Today';
      } else if (chatDate.toDateString() === yesterday) {
        group = 'Yesterday';
      } else {
        group = 'Last Week';
      }

      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push(chat);
      return acc;
    }, {});
  }, [chatSessions]);

  const selectSession = async (chatSession: ChatSession) => {
    dispatch(setSelectedSession(chatSession));
  };

  const handleSignOut = async () => { 
    const response = await authService.signOut();

    if (response.kind == ResponseKind.OK) {
      navigate(authRoutes.LOGIN, { replace: true });
      clearToken();
      dispatch(resetUser());
      dispatch(clearChatState());
      logout();
    }
  };

  const signOut = async () => {
    setIsSignOutModalOpen(true);
  };

  return (
    <Navbar
      hiddenBreakpoint={768}
      px="xs"
      className={classes.parentContainer}
      hidden={!opened}
      width={{
        base: 320,
      }}
    >
      <div style={{height: isMobile ? 'calc((var(--vh, 1vh) * 100) - 40px)' : '100%'}} className="flex flex-col text-gray-800 w-full p-4 pt-0">
        <ConfirmFeedbackModal
          title="Sign Out"
          feedback="Are you sure you want to sign out?"
          onConfirm={handleSignOut}
          onClose={() => setIsSignOutModalOpen(false)}
          visible={isSignOutModalOpen}
          confirmLabel="Sign Out"
        />

        <div className="flex items-center space-x-3 mb-5 md:my-5">
          <div>
            <Text className="font-bold text-xl">{user.name}</Text>
            <Text className="text-sm text-gray-600">{user.level}</Text>
            <Text className="text-sm text-gray-600">Science</Text>
          </div>
        </div>

        <ChatSessionsList 
        groupedChats={groupedChats} 
        onNewSession={newSession} 
        onDeleteSession={deleteSession} 
        onSelectSession={selectSession} 
        selectedSession={selectedSession.id} />


        <div className="flex-shrink-0 mt-auto">
        <CustomButton
          onClick={signOut}
          variant="ghost"
          size="default"
          leftIcon="logout"
          alignment='left'
          className="text-sm"
        >
          Sign Out
        </CustomButton>
        </div>

      </div>
    </Navbar>

  );
};

export default Sidebar;